<template>
  <a-card>
    <p>模板使用详情</p>
    <div class="tools">
      <a-button type="primary" icon="export" @click="exportExcel">导出</a-button>
    </div>
    <div>
      <a-table
          :columns="columns"
          :data-source="list"
          :pagination="false"
          :rowKey="(record, index) => index"
          :loading="isLoading"
          class="mt-40p"
      >
        <div slot="dealerInfo" slot-scope="text, record">
          <div class="row-box box-1">
            <div><span>经销商名称：</span></div>
            <div>{{ record.dealer_name || "--" }}</div>
          </div>
          <div class="row-box box-1">
            <div><span>归属品牌：</span></div>
            <div>{{ record.principal_names || "--" }}</div>
          </div>
        </div>
        <div slot="moduleInfo" slot-scope="text, record">
          <div class="row-box box-1">
            <div><span>模板名称：</span></div>
            <div>{{ record.template_name || "--" }}</div>
          </div>
          <div class="row-box box-1">
            <div><span>模板类型：</span></div>
            <div>{{ record.template_tag_name || "--" }}</div>
          </div>
        </div>
        <div slot="videoInfo" slot-scope="text, record">
          <div class="row-box box-1">
            <div><span style="color:#2A90F4;">视频名称：</span></div>
            <a href="#" @click="() => showVideoModal(record)">{{ record.aweme_title || "-" }}</a>
          </div>
          <div class="row-box box-1">
            <div><span>发布平台：</span></div>
            <div>{{ record.platform_type_name || "--" }}</div>
          </div>
          <div class="row-box box-1">
            <div><span>发布时间：</span></div>
            <div>{{ record.source_ctime || "--" }}</div>
          </div>
          <div class="row-box box-1">
            <div><span>统计时间：</span></div>
            <div>{{ record.time || "--" }}</div>
          </div>
        </div>
        <div slot="moduleTypeInfo" slot-scope="text, record">
          <div class="row-box box-1">
            <div><span>视频播放次数：</span></div>
            <div>{{ record.play_volume || "--" }}</div>
          </div>
          <div class="row-box box-1">
            <div><span>视频点赞数：</span></div>
            <div>{{ record.digg_count || "--" }}</div>
          </div>
          <div class="row-box box-1">
            <div><span>视频分享数：</span></div>
            <div>{{ record.share_count || "--" }}</div>
          </div>
          <div class="row-box box-1">
            <div><span>视频评论数：</span></div>
            <div>{{ record.comment_count || "--" }}</div>
          </div>
        </div>
        <div slot="TypeInfo" slot-scope="text, record">
          <div class="row-box box-1">
            <div><span>生成成片：</span></div>
            <div>{{ record.aweme_total || "--" }}</div>
          </div>
          <div class="row-box box-1">
            <div><span>发布视频：</span></div>
            <div>{{ record.publish_total || "--" }}</div>
          </div>
        </div>
      </a-table>
      <!-- 分页配置 -->
      <base-pagination
          :currentPage="pagination.current"
          :pageSize="pagination.page_size"
          :total="pagination.total"
          @onChange="onChange"
          @onShowSizeChange="onChange"
      >
      </base-pagination>
    </div>

    <!--视频播放-->
    <video-modal
        :preview-src="preview_src"
        :visible="preview_visible"
        @cancel="handlePreviewCancel"
    />
  </a-card>
</template>

<script>
import https from "@/api/kpi.js";
import uploadExcel from "@/utils/uploadExcel.js";
import videoModal from "@/components/videoModal/index";

export default {
  name: "template-detail",
  data() {
    return {
      columns: [
        {
          dataIndex: "",
          scopedSlots: {customRender: "dealerInfo"},
          title: "经销商信息"
        },
        {
          dataIndex: "",
          scopedSlots: {customRender: "moduleInfo"},
          title: "模板信息"
        },
        {
          dataIndex: "",
          scopedSlots: {customRender: "videoInfo"},
          title: "视频信息"
        },
        {
          dataIndex: "",
          scopedSlots: {customRender: "TypeInfo"},
          title: "模板使用信息"
        },
        {
          dataIndex: "",
          scopedSlots: {customRender: "moduleTypeInfo"},
          title: "模板转化效果"
        },
      ],
      list: [],
      isLoading: false,
      dealer_id: this.$route.query.dealer_id,
      start_time: this.$route.query.start_time,
      end_time: this.$route.query.end_time,
      platform_type: this.$route.query.platform_type,
      pagination: {
        total: 0,
        current: 1,
        page_size: 10
      },
      preview_src: "",
      preview_visible: false,
    };
  },
  components: {videoModal},
  mounted() {
    if(this.dealer_id && this.start_time && this.end_time) {
      this.getTemplateDetail();
    }
  },
  methods: {
    getTemplateDetail() {
      this.isLoading = true;
      let params = {
        // dealer_id: "1314afanti4",
        dealer_id: this.dealer_id,
        start_time: this.start_time,
        end_time: this.end_time,
        page_num: this.pagination.current,
        page_size: this.pagination.page_size,
        platform_type:this.platform_type
      };
      https.getTemplateDetail(params).then(res => {
        this.isLoading = false;
        if(res.code == 200) {
          this.list = res.data.list || [];
          this.pagination.total = res.data.total;
        } else {
          this.TabList = [];
          this.$message.error(res.message || "获取模板列表失败");
        }
      });
    },
    exportExcel() {
      let data = Object.assign({
        dealer_id: this.dealer_id,
        start_time: this.start_time,
        end_time: this.end_time
      });
      let url = "/activities/template/detail/export";
      return uploadExcel(
          url,
          data,
          "模板使用详情"
      );
    },
    onChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.page_size = pageSize;
      this.getTemplateDetail();
    },
    showVideoModal(record) {
      this.preview_visible = true;
      if(!record.aweme_url) {
        this.$message.error("暂无播放链接");
        return false;
      }
      this.preview_src = record.aweme_url || "";
    },
    handlePreviewCancel() {
      this.preview_visible = false;
      this.preview_src = "";
    },
  }
};
</script>

<style scoped lang="less">
.tools {
  padding: 15px 0;
}

.row-box {
  display: flex;
  margin-bottom: 5px;

  & > div:first-child {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-weight: bold;

    .title {
      display: flex;
      justify-content: space-between;
    }
  }

  & > div:last-child {
    flex: 1;
    min-width: 50px;
  }
}

.box-1 {
  & > div:first-child {
    width: 70px;
  }
}
</style>
